import { required, maxLength } from "vuelidate/lib/validators";
import { onlyAlphanumeric, nameLength } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        HeadingNameEs: { required, onlyAlphanumeric, maxLength: maxLength(250), nameLength },
        HeadingNameEn: { required, onlyAlphanumeric, maxLength: maxLength(250), nameLength },
    }
}

